import './index.scss';

import { APP_URL } from 'config/constants';
import Success from 'images/CNY2023/success-submit.webp';
import FB from 'images/DelMonteCNYContest/FB.webp';
import IG from 'images/DelMonteCNYContest/IG.webp';
import Bakestarters from 'images/SGJuniorBakeOff2024/Bakestarters.webp';
import Banner from 'images/SGJuniorBakeOff2024/banner.webp';
import Bosch from 'images/SGJuniorBakeOff2024/Bosch.webp';
import Category1 from 'images/SGJuniorBakeOff2024/Category1.webp';
import Category2 from 'images/SGJuniorBakeOff2024/Category2.webp';
import Category3 from 'images/SGJuniorBakeOff2024/Category3.webp';
import CompetetionTimeline from 'images/SGJuniorBakeOff2024/CompetetionTimeline.webp';
import Daucy from 'images/SGJuniorBakeOff2024/Daucy.webp';
import Dole from 'images/SGJuniorBakeOff2024/Dole.webp';
import Event1 from 'images/SGJuniorBakeOff2024/Event1.webp';
import Event2 from 'images/SGJuniorBakeOff2024/Event2.webp';
import FinalistPrize from 'images/SGJuniorBakeOff2024/FinalistPrize.webp';
import FirstPrizeBaking from 'images/SGJuniorBakeOff2024/FirstPrizeBaking.webp';
import FirstPrizeCake from 'images/SGJuniorBakeOff2024/FirstPrizeCake.webp';
import FirstPrizeCooking from 'images/SGJuniorBakeOff2024/FirstPrizeCooking.webp';
import Globe from 'images/SGJuniorBakeOff2024/globe.webp';
import Hershey from 'images/SGJuniorBakeOff2024/Hershey.webp';
import Judge1 from 'images/SGJuniorBakeOff2024/Judge1.webp';
import Judge2 from 'images/SGJuniorBakeOff2024/Judge2.webp';
import Judge3 from 'images/SGJuniorBakeOff2024/Judge3.webp';
import Judge4 from 'images/SGJuniorBakeOff2024/Judge4.webp';
import Judge5 from 'images/SGJuniorBakeOff2024/Judge5.webp';
import MitrPhol from 'images/SGJuniorBakeOff2024/MitrPhol.webp';
import SCS from 'images/SGJuniorBakeOff2024/SCS.webp';
import SecondPrizeBaking from 'images/SGJuniorBakeOff2024/SecondPrizeBaking.webp';
import SecondPrizeCake from 'images/SGJuniorBakeOff2024/SecondPrizeCake.webp';
import SecondPrizeCooking from 'images/SGJuniorBakeOff2024/SecondPrizeCooking.webp';
import ThirdPrizeBaking from 'images/SGJuniorBakeOff2024/ThirdPrizeBaking.webp';
import ThirdPrizeCake from 'images/SGJuniorBakeOff2024/ThirdPrizeCake.webp';
import ThirdPrizeCooking from 'images/SGJuniorBakeOff2024/ThirdPrizeCooking.webp';
import Tiktok from 'images/SGJuniorBakeOff2024/tiktok.webp';
import Tott from 'images/SGJuniorBakeOff2024/Tott.webp';
import Workshop1 from 'images/SGJuniorBakeOff2024/Workshop1.webp';
import Workshop2 from 'images/SGJuniorBakeOff2024/Workshop2.webp';
import Workshop3 from 'images/SGJuniorBakeOff2024/Workshop3.webp';
import Workshop4 from 'images/SGJuniorBakeOff2024/Workshop4.webp';
import Workshop5 from 'images/SGJuniorBakeOff2024/Workshop5.webp';
import Workshop6 from 'images/SGJuniorBakeOff2024/Workshop6.webp';
import Workshop7 from 'images/SGJuniorBakeOff2024/Workshop7.webp';
import Workshop8 from 'images/SGJuniorBakeOff2024/Workshop8.webp';
import Workshop9 from 'images/SGJuniorBakeOff2024/Workshop9.webp';
import Workshop10 from 'images/SGJuniorBakeOff2024/Workshop10.webp';
import Workshop11 from 'images/SGJuniorBakeOff2024/Workshop11.webp';
import Workshop12 from 'images/SGJuniorBakeOff2024/Workshop12.webp';
import Workshop13 from 'images/SGJuniorBakeOff2024/Workshop13.webp';
import Workshop14 from 'images/SGJuniorBakeOff2024/Workshop14.webp';
import Workshop15 from 'images/SGJuniorBakeOff2024/Workshop15.webp';
import WorkshopInstrctor from 'images/SGJuniorBakeOff2024/WorkshopInstructor.webp';
import WorkshopTimeline from 'images/SGJuniorBakeOff2024/WorkshopTimeline.webp';
import React, { memo, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const MetaBanner = 'https://yoripe-s3-production.s3.ap-southeast-1.amazonaws.com/web-assets/SGJuniorBakeOff2024Banner.webp';

function openInNewTab(url) {
    if (url) {
        window.open(url, '_blank').focus();
    }
}

function SGJuniorBakeOff2024() {
    const { pathname } = useLocation();
    const termsRef = useRef();
    const formRef = useRef();
    const location = useLocation();
    const joined = useMemo(() => {
        return location.pathname === '/sgjuniorbakeoff2024/joined';
    }, [location.pathname]);

    return (
        <>
            <Helmet>
                <title>{'YoRipe Singapore Junior Bake-Off 2024'}</title>
                <meta name='title' content={'YoRipe Singapore Junior Bake-Off 2024'} />
                <meta name='description' content='The largest baking event is here! YoRipe X ToTT Present Singapore Junior Bake-off 2024. Win Total Prizes up to $3000+ worth of BOSCH appliances, ToTT products, Bakestarters baking kit, Universal Studio tickets, and Toys R Us Vouchers.' />

                <meta property='og:type' content='website' />
                <meta property='og:url' content={APP_URL + pathname} />
                <meta property='og:title' content={'YoRipe Singapore Junior Bake-Off 2024'} />
                <meta property='og:description' content='The largest baking event is here! YoRipe X ToTT Present Singapore Junior Bake-off 2024. Win Total Prizes up to $3000+ worth of BOSCH appliances, ToTT products, Bakestarters baking kit, Universal Studio tickets, and Toys R Us Vouchers.' />
                <meta property='og:image' content={MetaBanner} />

                <meta property='twitter:card' content='summary_large_image' />
                <meta property='twitter:url' content={APP_URL + pathname} />
                <meta property='twitter:title' content={'YoRipe Singapore Junior Bake-Off 2024'} />
                <meta property='twitter:description' content='The largest baking event is here! YoRipe X ToTT Present Singapore Junior Bake-off 2024. Win Total Prizes up to $3000+ worth of BOSCH appliances, ToTT products, Bakestarters baking kit, Universal Studio tickets, and Toys R Us Vouchers.' />
                <meta property='twitter:image' content={MetaBanner} />
            </Helmet>
            <div className='page-wrap invis-scroll'>
                {joined && <div className='lp-sections-container background-white'><div className='lp-content-wrapper background-white text-center'>
                    <img src={Success} alt='YoRipe Singapore Junior Bake-Off 2024' className='submission-success' />
                    <div className='text-sub-title text-green'>Your entry to the workshop has been submitted! 🤩 🎉</div>
                    <span className='text-small'>Thank you for joining! The sign up closes on 4 November 2024.</span>
                    <button onClick={() => openInNewTab('https://web.yoripe.com/sgjuniorbakeoff2024')} className='download-highlight-button'>Back to YoRipe SG Junior Bake-Off 2024</button>
                </div></div>}
                {!joined && <>
                    <div className='lp-sections-container'><img src={Banner} alt='YoRipe Singapore Junior Bake-Off 2024' className='lp-2024-banner' /></div>
                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper'>
                            <div className='text-title text-theme'>Singapore Junior Bake-Off 2024</div>
                            <p className='text'>The most exciting local junior culinary contest featuring children and teens from 5-17 years old <strong>IS BACK WITH A BAKING EDITION!</strong>
                                <br/>The Junior Bake-Off has two components - Workshop & Competition; offering an educational and fun experience for children and teens to learn from professional chefs, showcase their baking talent and <strong>WIN ATTRACTIVE PRIZES!</strong>
                            </p>
                        </div>
                    </div>
                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper border-top'>
                            <div className='text-title text-theme'>Competition</div>
                            <div className='text-sub-title text-theme margin-top-12'>WIN attractive prizes for Top 3 from each categories 🔥</div>
                            <p className='text'>The winners will get awesome cooking tools from ToTT, BOSCH, and of course, tickets to Universal Studios and Toys R Us vouchers!</p>
                        </div>
                    </div>

                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper'>
                            <div className='text-title'>Prizes</div>
                            <div className='text-title'>Cookies & Decoration</div>
                            <div>{'Swipe for more details >>'}</div>
                            <div className='carousel-scroll pretty-scroll'>
                                <img src={FirstPrizeCooking} alt='Cooking & Decoration First Prize' className='carousel-scroll-image' />
                                <img src={SecondPrizeCooking} alt='Cooking & Decoration Second Prize' className='carousel-scroll-image' />
                                <img src={ThirdPrizeCooking} alt='Cooking & Decoration Third Prize' className='carousel-scroll-image' />
                            </div>
                        </div>
                    </div>

                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper'>
                            <div className='text-title'>Savory & Baking</div>
                            <div>{'Swipe for more details >>'}</div>
                            <div className='carousel-scroll pretty-scroll'>
                                <img src={FirstPrizeBaking} alt='Savoury & Baking First Prize' className='carousel-scroll-image' />
                                <img src={SecondPrizeBaking} alt='Savoury & Baking Second Prize' className='carousel-scroll-image' />
                                <img src={ThirdPrizeBaking} alt='Savoury & Baking Third Prize' className='carousel-scroll-image' />
                            </div>
                        </div>
                    </div>

                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper'>
                            <div className='text-title'>Cake</div>
                            <div>{'Swipe for more details >>'}</div>
                            <div className='carousel-scroll pretty-scroll'>
                                <img src={FirstPrizeCake} alt='Cake First Prize' className='carousel-scroll-image' />
                                <img src={SecondPrizeCake} alt='Cake Second Prize' className='carousel-scroll-image' />
                                <img src={ThirdPrizeCake} alt='Cake Third Prize' className='carousel-scroll-image' />
                            </div>
                        </div>
                    </div>

                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper'>
                            <div className='text-title'>Finalists from All Categories</div>
                            <img src={FinalistPrize} alt='Finalist Prize' className='lp-section-image' />
                        </div>
                    </div>

                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper'>
                            <div className='text-title text-theme'>Categories</div>
                            <img src={Category1} onClick={() => openInNewTab('https://yoripeapp.app.link/5DtvHuiHuNb')} alt='Cookies & Decoration'
                                className='lp-section-image hoverable' />
                            <img src={Category2} onClick={() => openInNewTab('https://yoripeapp.app.link/h62MkDhNpNb')} alt='Savory & Baking'
                                className='lp-section-image hoverable' />
                            <img src={Category3} onClick={() => openInNewTab('https://yoripeapp.app.link/ScaHSNENpNb')} alt='Cake'
                                className='lp-section-image hoverable' />
                        </div>
                    </div>
                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper'>
                            <div className='text-title text-theme'>Judges</div>
                            <div className='judges-container'>
                                <img onClick={() => openInNewTab('https://www.instagram.com/bakeomaniac/')} src={Judge1} alt='Javier - YoRipe Creator'
                                    className='judges-image hoverable' />
                                <img onClick={() => openInNewTab('https://www.instagram.com/shuga_attaque/')} src={Judge2} alt='Nisa - YoRipe Creator'
                                    className='judges-image hoverable' />
                                <img onClick={() => openInNewTab('https://www.instagram.com/bakestarters/')} src={Judge3} alt='Gregory Ong - Bakestarters'
                                    className='judges-image hoverable' />
                                <img src={Judge4} alt='Nisa - YoRipe Creator'
                                    className='judges-image hoverable' />
                                <img src={Judge5} alt='Judge to be announced' className='judges-image' />
                            </div>
                        </div>
                    </div>
                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper'>
                            <img src={CompetetionTimeline} alt='Competetion Timeline' className='lp-section-image' />
                        </div>
                    </div>
                    <div className='lp-sections-container background-white'>
                        <div className='lp-content-wrapper background-white'>
                            <span className='text-title'>HOW TO JOIN</span>
                            <div className='term-container margin-top-12'>
                                <span className='terms-count'><strong>1.</strong></span>
                                <div className='term-text'><strong>Download</strong> the YoRipe App from the Play Store or App Store.</div>
                            </div>
                            <div className='term-container'>
                                <span className='terms-count'><strong>2.</strong></span>
                                <div className='term-text'>Go to the Experience Page, and click on the "SG Junior Bake-Off" tab, you'll see the 3 Categories: <strong>#Cookies&Decoration | #SavouryBaking | #CakeChallenge</strong></div>
                            </div>
                            <div className='term-container'>
                                <span className='terms-count'><strong>3.</strong></span>
                                <div className='term-text'>Click on the category hashtag you wish to join, click on "<strong>Join Now</strong>" button and upload a baked goods that you made.</div>
                            </div>
                            <div className='term-container'>
                                <span className='terms-count'><strong>4.</strong></span>
                                <div className='term-text'><strong>Share</strong> the same baked goods on your social media tagging @helloyoripe on IG or YoRipe on Facebook.</div>
                            </div>
                            <p className='term-text'>
                                <br/>{'You can join more than 1 category :)'}<br/><br/>Questions? DM us on @helloyoripe on IG or write to contact@yoripe.com
                            </p>
                        </div>
                    </div>
                    <div className='lp-sections-container background-white'>
                        <div className='lp-content-wrapper background-white text-center border-top'>
                            <div className='text-title text-theme'>Baking Workshops</div>
                            <img src={Event1} alt='Workshop' className='lp-section-image margin-top-24' />
                            <img src={Event2} alt='Workshop' className='lp-section-image margin-top-24' />
                        </div>
                    </div>
                    <div className='lp-sections-container background-white'>
                        <div className='lp-content-wrapper background-white'>
                            <div className='text-title margin-top-24'>Workshop Instructor</div>
                            <img onClick={() => openInNewTab('https://tottstore.com/classes/tottchef/michele-ow/')} src={WorkshopInstrctor} alt='Workshop Instructor'
                                className='lp-section-image margin-top-24 hoverable' />
                        </div>
                    </div>
                    <div className='lp-sections-container background-white'>
                        <div className='lp-content-wrapper background-white'>
                            <img src={WorkshopTimeline} alt='Workshop Timeline' className='lp-section-image' />
                        </div>
                    </div>
                    <div className='lp-sections-container background-white'>
                        <div className='lp-content-wrapper background-white'>
                            <div className='text-title'>Venue</div>
                            <div className='text-venue margin-top-12'><strong>ToTT Studio</strong></div>
                            <div className='text-venue text-center'>i12 Katong, 112 East Coast Road, #02-09/10 Singapore 428802</div>
                        </div>
                    </div>
                    <div className='disclaimer-container'>
                    Each workshop's maximum capacity is 20 pax. <strong>Fill in the form below to join workshop!</strong>
                    </div>
                    <div className='lp-sections-container background-white'><div className='lp-content-wrapper background-white text-center border-top'>
                        <div ref={formRef} className='tally-form-wrapper margin-top-24'>
                            <div className='text-title text-theme'>Fill in the interest form</div>
                            <div className='text-small'>We'll contact you to complete the registration, terms & conditions applied.</div>
                            <iframe title='Poll' width='620' height='1300'
                                src='https://tally.so/embed/wQJ0RG?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1'
                                style={{ width: '100%', height: '1000px' }} frameBorder='0'
                                allowFullScreen={true} />
                        </div>
                    </div></div>
                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper border-top'>
                            <div className='text-title text-theme'>Previous Workshops & Competitions</div>
                            <div className='carousel-scroll pretty-scroll'>
                                <img src={Workshop1} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop2} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop3} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop4} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop5} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop6} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop7} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop8} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop9} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop10} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop11} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop12} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop13} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop14} alt='Past Workshop' className='carousel-scroll-image' />
                                <img src={Workshop15} alt='Past Workshop' className='carousel-scroll-image' />
                            </div>
                        </div>
                    </div>
                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper'>
                            <div className='text-title text-theme'>Presenting Partner</div>
                            <div className='partner-wrapper'>
                                <img src={Tott} alt='Presenting Partner' className='brand-partner' />
                                <div className='socials-container'>
                                    <div className='lp-brand-socials'>
                                        <img src={IG} alt='Tott Instagram'/>
                                        <a target='_blank' href='https://www.instagram.com/tottstore/' rel='noreferrer'>@tottstore</a>
                                    </div>
                                    <div className='lp-brand-socials'>
                                        <img src={FB} alt='Tott Facebook'/>
                                        <a target='_blank' href='https://www.facebook.com/ToTTStore?ref=br_tf' rel='noreferrer'>TOTT</a>
                                    </div>
                                    <div className='lp-brand-socials'>
                                        <img src={Tiktok} alt='Tott Tiktok'/>
                                        <a target='_blank' href='https://www.tiktok.com/@tott.store' rel='noreferrer'>@tott.store</a>
                                    </div>
                                    <div className='lp-brand-socials'>
                                        <img src={Globe} alt='Tott Website'/>
                                        <a target='_blank' href='https://tottstore.com' rel='noreferrer'>tottstore.com</a>
                                    </div>
                                </div>
                                <p className='partner-description'>
                            ToTT Store is a one-stop culinary wonderland that inspires you to Cook, Bake and Host, with a curated selection of quality kitchen appliances, cookware, bakeware, tableware and home ware goods. Selected store feature state-of-the-art cooking studios that offer a comprehensive range of cooking and baking classes, private parties and team-building programs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper'>
                            <div className='text-title text-theme'>Official Appliances Partner</div>
                            <div className='partner-wrapper'>
                                <img src={Bosch} alt='Appliances Partner' className='brand-partner' />
                                <div className='socials-container'>
                                    <div className='lp-brand-socials'>
                                        <img src={IG} alt='Bosch Instagram'/>
                                        <a target='_blank' href='https://www.instagram.com/boschhomesg/' rel='noreferrer'>@boschhomesg</a>
                                    </div>
                                    <div className='lp-brand-socials'>
                                        <img src={FB} alt='Bosch Facebook'/>
                                        <a target='_blank' href='https://www.facebook.com/BoschHomeSG/' rel='noreferrer'>Bosch Home</a>
                                    </div>
                                    <div className='lp-brand-socials'>
                                        <img src={Globe} alt='Bosch Website'/>
                                        <a target='_blank' href='https://www.bosch.com.sg' rel='noreferrer'>Bosch Singapore</a>
                                    </div>
                                </div>
                                <p className='partner-description'>
                            Bosch Home Appliances - a globally recognized brand known for its excellent quality and reliability in home appliances, offering trendsetting technology, simple solutions, and a timeless design to simplify everyday life and achieve perfect results.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper'>
                            <div className='text-title text-theme'>Official Ingredient Partners</div>
                            <div className='partner-wrapper'>
                                <img src={Dole} alt='Ingredient Partner' className='brand-partner' />
                                <div className='socials-container'>
                                    <div className='lp-brand-socials'>
                                        <img src={IG} alt='Dole Instagram'/>
                                        <a target='_blank' href='https://www.instagram.com/dole.singapore/' rel='noreferrer'>@dole.singapore</a>
                                    </div>
                                    <div className='lp-brand-socials'>
                                        <img src={FB} alt='Dole Facebook'/>
                                        <a target='_blank' href='https://www.facebook.com/dole.singapore/' rel='noreferrer'>Dole Singapore</a>
                                    </div>
                                </div>
                                <p className='partner-description'>
                            A leader in growing and marketing fresh & packaged fruits, we champion "Sunshine for All" - a world where everyone has access to healthy nutrition.
                                </p>
                            </div>
                            <div className='partner-wrapper'>
                                <img src={MitrPhol} alt='Ingredient Partner' className='brand-partner' />
                                <div className='socials-container'>
                                    <div className='lp-brand-socials'>
                                        <img src={FB} alt='Mitr Phol Facebook'/>
                                        <a target='_blank' href='https://www.facebook.com/mitrpholsugarsingapore' rel='noreferrer'>Mitr Phol Sugar Singapore</a>
                                    </div>
                                </div>
                                <p className='partner-description'>
                            Mitr Phol Sugar, Asia's leading sugar company, with international standards, hygienic and friendly to the environment under the commitment of "Mitr Phol Sugar, Quality in Every Crystal"
                                </p>
                            </div>
                            <div className='partner-wrapper'>
                                <img src={SCS} alt='Ingredient Partner' className='brand-partner' />
                                <div className='socials-container'>
                                    <div className='lp-brand-socials'>
                                        <img src={FB} alt='SCS Facebook'/>
                                        <a target='_blank' href='https://www.facebook.com/SCSdairySG' rel='noreferrer'>SCS Dairy Singapore</a>
                                    </div>
                                </div>
                                <p className='partner-description'>
                            Double churned butter for that extra creamy goodness, Chefs and homemakers choose SCS as their preferred everyday choice. Singapore's Number 1 Butter Brand.
                                </p>
                            </div>
                            <div className='partner-wrapper'>
                                <img src={Hershey} alt='Ingredient Partner' className='brand-partner' />
                                <div className='socials-container'>
                                    <div className='lp-brand-socials'>
                                        <img src={IG} alt='Hershey Instagram'/>
                                        <a target='_blank' href='https://www.instagram.com/hersheycompany/' rel='noreferrer'>@hersheycompany</a>
                                    </div>
                                </div>
                                <p className='partner-description'>
                            Enhance every moments with a sweet treat.
                                </p>
                            </div>
                            <div className='partner-wrapper'>
                                <img src={Daucy} alt='Ingredient Partner' className='brand-partner' />
                                <div className='socials-container'>
                                    <div className='lp-brand-socials'>
                                        <img src={IG} alt='Hershey Instagram'/>
                                        <a target='_blank' href='https://www.instagram.com/daucyfr/' rel='noreferrer'>@daucyfr</a>
                                    </div>
                                </div>
                                <p className='partner-description'>
                                For 50 years, d’aucy, a cooperative brand, has been committed to offer the best vegetables on an everyday basis, thanks to its 1,500 member farmers who over the years have established a relationship of trust with consumer.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='lp-sections-container'>
                        <div className='lp-content-wrapper'>
                            <div className='text-title text-theme'>Official Community Partner</div>
                            <div className='partner-wrapper'>
                                <img src={Bakestarters} alt='Community Partner' className='brand-partner' />
                                <div className='socials-container'>
                                    <div className='lp-brand-socials'>
                                        <img src={IG} alt='Bosch Instagram'/>
                                        <a target='_blank' href='https://www.instagram.com/bakestarters/' rel='noreferrer'>@bakestarters</a>
                                    </div>
                                </div>
                                <p className='partner-description'>
                                Bakestarters reinvents your baking experience to one that's without hassle and food wastage.
                                </p>
                            </div>
                        </div>
                    </div>
                </>}
                <div className='lp-sections-container background-white'><div className='lp-content-wrapper background-white border-top'>
                    <div className='text-title text-theme' ref={termsRef}>Terms & Conditions</div>
                    <span className='term-title'>Workshop Terms & Conditions</span>
                    <div className='term-container margin-top-12'>
                        <span className='terms-count'>1.</span>
                        <div className='term-text'>This event is open to all residents of Singapore.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>2.</span>
                        <div className='term-text'>For Workshops, sign up entry must be accompanied by original receipt with minimum $10 of the official ingredient partner products: SCS, Hershey's, Mitr Phol and Dole made during the eligible sign up period from 7 Oct - 8 November 2024. All registrations must be received by 8 November 2024, submitted via the form as shown.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>3.</span>
                        <div className='term-text'>Entries received after the closing date will not be eligible for the sign up. Incomplete, illegible or inaccurate entries will not be accepted.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>4.</span>
                        <div className='term-text'>All sign ups submitted will be contacted within 48 hours.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>5.</span>
                        <div className='term-text'>Participants will be notified through WhatsApp and will be dropped in a WhatsApp group for ease of providing additional information.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>6.</span>
                        <div className='term-text'>YoRipe reserves the right to substitute the Sign Up participant with another participant if the first participant drops out or doesn't answer within 24 hours upon first contact.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>7.</span>
                        <div className='term-text'>All participants of workshops must read and qualify for the respective choice of workshop's terms & conditions. YoRipe reserves the right to substitute the Sign Up participant with another participant if the first participant does not qualify and refunds will not be given.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>8.</span>
                        <div className='term-text'>The decision of YoRipe and brands/vendors on all matters relating to or in connection with the contest is final, and no correspondence will be entertained.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>9.</span>
                        <div className='term-text'>Ingredients of the recipes will be provided for all participants at the venue itself. Ingredients or any other equipment is not transferable or exchangeable for cash.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>10.</span>
                        <div className='term-text'>All transportation, accommodation, personal cost and/or any other related expenses that are incurred to travel or participate in the workshop are the sole responsibility of the entrants and winners. Entrants shall assume full responsibility in case of any liability, mishap, personal injury, damage, claim or accidents resulting from their participation in this workshop and agree to release YoRipe and brands/vendors of any liability.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>11.</span>
                        <div className='term-text'>By participating in this workshop, you consent to the use and/or disclosure of your personal information contained herein for internal and/or external marketing purposes in a manner it deems fit, whilst according to your privacy or confidentiality in the highest priority.</div>
                    </div>

                    <span className='term-title'>Competetion Terms & Conditions</span>
                    <div className='term-container margin-top-12'>
                        <span className='terms-count'>1.</span>
                        <div className='term-text'>Open for kids to teens from 5-17 years old.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>2.</span>
                        <div className='term-text'>Each participant can only enter the specific category based on their age range: - Cookies (5 to 12 years old) - Cake OR Savory Baking (12 to 17 years).</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>3.</span>
                        <div className='term-text'>Each participant can only enter the Competition once.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>4.</span>
                        <div className='term-text'>Each household can have more than 1 child entering the competition.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>5.</span>
                        <div className='term-text'>To participate, all entrants must upload a picture of a dish that they have made and post it on the YoRipe App.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>6.</span>
                        <div className='term-text'>Nominations submissions end on 17 November 2024, 23.59.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>7.</span>
                        <div className='term-text'>During the cooking competition for the Cookies category (5-12 years old), parents can help with handling of hot equipment and utensils.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>8.</span>
                        <div className='term-text'>Winning criteria for the Nominations & Semi-Finals round: Judges (100%) which makes up of presentation (50%), complexity of the dish (30%) and creativity (20%).</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>9.</span>
                        <div className='term-text'>Winning criteria for the Finals round: voting (40%) and judges (60%) which makes up of presentation (50%), complexity of the dish (30%) and creativity (20%).</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>10.</span>
                        <div className='term-text'>All Winners will be tagged on YoRipe Winner announcement post on Facebook and Instagram. Each Winner will also be notified individually via WhatsApp, Facebook and/or Instagram private message.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>11.</span>
                        <div className='term-text'>Shortlisted Winners will be required to acknowledge receipt of the Private Message by providing the information requested in the Private Message (including but not limited to full name and address of the Winners, date of birth, mobile number, email address and delivery address) to the Promoter by no later than 14 days after the Private Message is sent, failing which his/her Prize will be forfeited automatically and the Promoter shall be free to deal with the Prize in any manner as it sees fit.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>12.</span>
                        <div className='term-text'>The organiser reserves the right in its sole and absolute discretion to change the Prizes as it sees fit and deal with any unclaimed Prizes in any manner.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>13.</span>
                        <div className='term-text'>The Winners shall, if required by the Organiser, provide his/her original Identity Card / Passport for verification of proof of eligibility for receipt of the Prize.</div>
                    </div>
                    <div className='term-container'>
                        <span className='terms-count'>14.</span>
                        <div className='term-text'>The Prizes will be dispatched to the relevant Winners within 30 days from the receipt of acknowledgement from the Winner.</div>
                    </div>
                </div></div>
            </div>
        </>
    );
}

export default memo(SGJuniorBakeOff2024);
